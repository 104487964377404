<template>
  <div  v-if="searchViewActive"  class="search">
    <div class="table-box" style="width: 1300px; overflow-x: scroll; ">
      <div class="el-table--fit" style="width: max-content;">
        <el-table :data="tableData" class="el-table__header" tooltip-effect="dark">
          <el-table-column label="ID" prop="num" min-width="100">
            <template slot-scope="scope">
              {{ ((pageinfo.page - 1) * pageinfo.size) + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column label="CID" prop="CID" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.CID" placement="top-start">
                <div slot="content">{{ scope.row.CID }}</div>
                <div class="btn">{{ scope.row.CID }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="CAS" prop="CAS" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.CAS" placement="top-start">
                <div slot="content">{{ scope.row.CAS }}</div>
                <div class="btn">{{ scope.row.CAS }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="common_name" prop="common_name" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.common_name" placement="top-start">
                <div slot="content">{{ scope.row.common_name }}</div>
                <div class="btn">{{ scope.row.common_name }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="M2OR" prop="M2OR" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.M2OR" placement="top-start">
                <div slot="content">{{ scope.row.M2OR }}</div>
                <div class="btn">{{ scope.row.M2OR }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Large_scale" prop="Large_scale" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Large_scale" placement="top-start">
                <div slot="content">{{ scope.row.Large_scale }}</div>
                <div class="btn">{{ scope.row.Large_scale }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="FlavlrDB" prop="FlavlrDB" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.FlavlrDB" placement="top-start">
                <div slot="content">{{ scope.row.FlavlrDB }}</div>
                <div class="btn">{{ scope.row.FlavlrDB }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="FlavorDB缺失的项" prop="FlavorDBlack" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.FlavorDBlack" placement="top-start">
                <div slot="content">{{ scope.row.FlavorDBlack}}</div>
                <div class="btn">{{ scope.row.FlavorDBlack }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="ORnet" prop="ORnet" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.ORnet" placement="top-start">
                <div slot="content">{{ scope.row.ORnet }}</div>
                <div class="btn">{{ scope.row.ORnet }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="chemBOOK号码" prop="chemBOOKnumber" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.chemBOOKnumber" placement="top-start">
                <div slot="content">{{ scope.row.chemBOOKnumber }}</div>
                <div class="btn">{{ scope.row.chemBOOKnumber }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="cmpdname" prop="cmpdname" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.cmpdname" placement="top-start">
                <div slot="content">{{ scope.row.cmpdname}}</div>
                <div class="btn">{{ scope.row.cmpdname }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="cmpdsynonym" prop="cmpdsynonym" min-width="350">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.cmpdsynonym" placement="top-start">
                <div slot="content">{{ scope.row.cmpdsynonym }}</div>
                <div class="btn">{{ scope.row.cmpdsynonym }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="mw" prop="mw" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.mw" placement="top-start">
                <div slot="content">{{ scope.row.mw }}</div>
                <div class="btn">{{ scope.row.mw }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="mf" prop="mf" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.mf" placement="top-start">
                <div slot="content">{{ scope.row.mf }}</div>
                <div class="btn">{{ scope.row.mf }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="polararea" prop="polararea" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.polararea" placement="top-start">
                <div slot="content">{{ scope.row.polararea }}</div>
                <div class="btn">{{ scope.row.polararea }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="complexity" prop="complexity" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.complexity" placement="top-start">
                <div slot="content">{{ scope.row.complexity}}</div>
                <div class="btn">{{ scope.row.complexity}}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="xlogp" prop="xlogp" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.xlogp" placement="top-start">
                <div slot="content">{{ scope.row.xlogp }}</div>
                <div class="btn">{{ scope.row.xlogp }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="heavycnt" prop="heavycnt" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.heavycnt" placement="top-start">
                <div slot="content">{{ scope.row.heavycnt }}</div>
                <div class="btn">{{ scope.row.heavycnt }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="hbonddonor" prop="hbonddonor" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.hbonddonor" placement="top-start">
                <div slot="content">{{ scope.row.hbonddonor }}</div>
                <div class="btn">{{ scope.row.hbonddonor }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="hbondacc" prop="hbondacc" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.hbondacc" placement="top-start">
                <div slot="content">{{ scope.row.hbondacc }}</div>
                <div class="btn">{{ scope.row.hbondacc }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="rotbonds" prop="rotbonds" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.rotbonds" placement="top-start">
                <div slot="content">{{ scope.row.rotbonds }}</div>
                <div class="btn">{{ scope.row.rotbonds }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="inchi" prop="inchi" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.inchi" placement="top-start">
                <div slot="content">{{ scope.row.inchi }}</div>
                <div class="btn">{{ scope.row.inchi }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="isosmiles" prop="isosmiles" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.isosmiles" placement="top-start">
                <div slot="content">{{ scope.row.isosmiles }}</div>
                <div class="btn">{{ scope.row.isosmiles }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="canonicalsmiles" prop="canonicalsmiles" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.canonicalsmiles" placement="top-start">
                <div slot="content">{{ scope.row.canonicalsmiles }}</div>
                <div class="btn">{{ scope.row.canonicalsmiles }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="inchikey" prop="inchikey" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.inchikey" placement="top-start">
                <div slot="content">{{ scope.row.inchikey }}</div>
                <div class="btn">{{ scope.row.inchikey }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="iupacname" prop="iupacname" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.iupacname" placement="top-start">
                <div slot="content">{{ scope.row.iupacname }}</div>
                <div class="btn">{{ scope.row.iupacname }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="exactmass" prop="exactmass" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.exactmass" placement="top-start">
                <div slot="content">{{ scope.row.exactmass }}</div>
                <div class="btn">{{ scope.row.exactmass }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="monoisotopicmass" prop="monoisotopicmass" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.monoisotopicmass" placement="top-start">
                <div slot="content">{{ scope.row.monoisotopicmass }}</div>
                <div class="btn">{{ scope.row.monoisotopicmass }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="charge" prop="charge" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.charge" placement="top-start">
                <div slot="content">{{ scope.row.charge }}</div>
                <div class="btn">{{ scope.row.charge }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="covalentunitcnt" prop="covalentunitcnt" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.covalentunitcnt" placement="top-start">
                <div slot="content">{{ scope.row.covalentunitcnt }}</div>
                <div class="btn">{{ scope.row.covalentunitcnt }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="isotopeatomcnt" prop="isotopeatomcnt" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.isotopeatomcnt" placement="top-start">
                <div slot="content">{{ scope.row.isotopeatomcnt }}</div>
                <div class="btn">{{ scope.row.isotopeatomcnt }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="totalatomstereocnt" prop="totalatomstereocnt" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.totalatomstereocnt" placement="top-start">
                <div slot="content">{{ scope.row.totalatomstereocnt }}</div>
                <div class="btn">{{ scope.row.totalatomstereocnt }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="definedatomstereocnt" prop="definedatomstereocnt" min-width="190">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.definedatomstereocnt" placement="top-start">
                <div slot="content">{{ scope.row.definedatomstereocnt }}</div>
                <div class="btn">{{ scope.row.definedatomstereocnt }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="undefinedatomstereocnt" prop="undefinedatomstereocnt" min-width="210">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.undefinedatomstereocnt" placement="top-start">
                <div slot="content">{{ scope.row.undefinedatomstereocnt }}</div>
                <div class="btn">{{ scope.row.undefinedatomstereocnt }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="totalbondstereocnt" prop="totalbondstereocnt" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.totalbondstereocnt" placement="top-start">
                <div slot="content">{{ scope.row.totalbondstereocnt }}</div>
                <div class="btn">{{ scope.row.totalbondstereocnt }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="definedbondstereocnt" prop="definedbondstereocnt" min-width="190">
            <template   slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.definedbondstereocnt" placement="top-start">
                <div slot="content">{{ scope.row.definedbondstereocnt }}</div>
                <div class="btn">{{ scope.row.definedbondstereocnt }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="undefinedbondstereocnt" prop="undefinedbondstereocnt" min-width="210">
            <template  slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.undefinedbondstereocnt" placement="top-start">
                <div slot="content">{{ scope.row.undefinedbondstereocnt }}</div>
                <div class="btn">{{ scope.row.undefinedbondstereocnt }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="pclidcnt" prop="pclidcnt" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.pclidcnt" placement="top-start">
                <div slot="content">{{ scope.row.pclidcnt }}</div>
                <div class="btn">{{ scope.row.pclidcnt }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="gpidcnt" prop="gpidcnt" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.gpidcnt" placement="top-start">
                <div slot="content">{{ scope.row.gpidcnt }}</div>
                <div class="btn">{{ scope.row.gpidcnt }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="gpfamilycnt" prop="gpfamilycnt" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.gpfamilycnt" placement="top-start">
                <div slot="content">{{ scope.row.gpfamilycnt }}</div>
                <div class="btn">{{ scope.row.gpfamilycnt }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="neighbortype" prop="neighbortype" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.neighbortype" placement="top-start">
                <div slot="content">{{ scope.row.neighbortype }}</div>
                <div class="btn">{{ scope.row.neighbortype }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="meshheadings" prop="meshheadings" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.meshheadings" placement="top-start">
                <div slot="content">{{ scope.row.meshheadings }}</div>
                <div class="btn">{{ scope.row.meshheadings }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="annothits" prop="annothits" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.annothits" placement="top-start">
                <div slot="content">{{ scope.row.annothits }}</div>
                <div class="btn">{{ scope.row.annothits }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="annothitcnt" prop="annothitcnt" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.annothitcnt" placement="top-start">
                <div slot="content">{{ scope.row.annothitcnt}}</div>
                <div class="btn">{{ scope.row.annothitcnt }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="aids" prop="aids" min-width="350">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.aids" placement="top-start">
                <div slot="content">{{ scope.row.aids }}</div>
                <div class="btn">{{ scope.row.aids }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="cidcdate" prop="cidcdate" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.cidcdate" placement="top-start">
                <div slot="content">{{ scope.row.cidcdate }}</div>
                <div class="btn">{{ scope.row.cidcdate }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="sidsrcname" prop="sidsrcname" min-width="350">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.sidsrcname" placement="top-start">
                <div slot="content">{{ scope.row.sidsrcname }}</div>
                <div class="btn">{{ scope.row.sidsrcname }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="depcatg" prop="depcatg" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.depcatg" placement="top-start">
                <div slot="content">{{ scope.row.depcatg }}</div>
                <div class="btn">{{ scope.row.depcatg }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="annotation" prop="annotation" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.annotation" placement="top-start">
                <div slot="content">{{ scope.row.annotation }}</div>
                <div class="btn">{{ scope.row.annotation }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SMILE" prop="SMILE" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SMILE" placement="top-start">
                <div slot="content">{{ scope.row.SMILE }}</div>
                <div class="btn">{{ scope.row.SMILE }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="MaxAbsEStateIndex" prop="MaxAbsEStateIndex" min-width="210">
            <template  slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.MaxAbsEStateIndex" placement="top-start">
                <div slot="content">{{ scope.row.MaxAbsEStateIndex }}</div>
                <div class="btn">{{ scope.row.MaxAbsEStateIndex }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="MaxEStateIndex" prop="MaxEStateIndex" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.MaxEStateIndex" placement="top-start">
                <div slot="content">{{ scope.row.MaxEStateIndex }}</div>
                <div class="btn">{{ scope.row.MaxEStateIndex }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="MinAbsEStateIndex" prop="MinAbsEStateIndex" min-width="210">
            <template  slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.MinAbsEStateIndex" placement="top-start">
                <div slot="content">{{ scope.row.MinAbsEStateIndex }}</div>
                <div class="btn">{{ scope.row.MinAbsEStateIndex }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="MinEStateIndex" prop="MinEStateIndex" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.MinEStateIndex" placement="top-start">
                <div slot="content">{{ scope.row.MinEStateIndex }}</div>
                <div class="btn">{{ scope.row.MinEStateIndex }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="qed" prop="qed" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.qed" placement="top-start">
                <div slot="content">{{ scope.row.qed }}</div>
                <div class="btn">{{ scope.row.qed }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SPS" prop="SPS" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SPS" placement="top-start">
                <div slot="content">{{ scope.row.SPS }}</div>
                <div class="btn">{{ scope.row.SPS }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="MolWt" prop="MolWt" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.MolWt" placement="top-start">
                <div slot="content">{{ scope.row.MolWt }}</div>
                <div class="btn">{{ scope.row.MolWt }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="HeavyAtomMolWt" prop="HeavyAtomMolWt" min-width="170">
            <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.HeavyAtomMolWt" placement="top-start">
                <div slot="content">{{ scope.row.HeavyAtomMolWt }}</div>
                <div class="btn">{{ scope.row.HeavyAtomMolWt }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="ExactMolWt" prop="ExactMolWt" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.ExactMolWt" placement="top-start">
                <div slot="content">{{ scope.row.ExactMolWt }}</div>
                <div class="btn">{{ scope.row.ExactMolWt }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumValenceElectrons" prop="NumValenceElectrons" min-width="210">
            <template  slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumValenceElectrons" placement="top-start">
                <div slot="content">{{ scope.row.NumValenceElectrons }}</div>
                <div class="btn">{{ scope.row.NumValenceElectrons }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumRadicalElectrons" prop="NumRadicalElectrons" min-width="210">
            <template  slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumRadicalElectrons" placement="top-start">
                <div slot="content">{{ scope.row.NumRadicalElectrons }}</div>
                <div class="btn">{{ scope.row.NumRadicalElectrons }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="MaxPartialCharge" prop="MaxPartialCharge" min-width="210">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.MaxPartialCharge" placement="top-start">
                <div slot="content">{{ scope.row.MaxPartialCharge }}</div>
                <div class="btn">{{ scope.row.MaxPartialCharge }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="MinPartialCharge" prop="MinPartialCharge" min-width="210">
            <template  slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.MinPartialCharge" placement="top-start">
                <div slot="content">{{ scope.row.MinPartialCharge }}</div>
                <div class="btn">{{ scope.row.MinPartialCharge }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="MaxAbsPartialCharge" prop="MaxAbsPartialCharge" min-width="210">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.MaxAbsPartialCharge" placement="top-start">
                <div slot="content">{{ scope.row.MaxAbsPartialCharge }}</div>
                <div class="btn">{{ scope.row.MaxAbsPartialCharge }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="MinAbsPartialCharge" prop="MinAbsPartialCharge" min-width="210">
            <template  slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.MinAbsPartialCharge" placement="top-start">
                <div slot="content">{{ scope.row.MinAbsPartialCharge }}</div>
                <div class="btn">{{ scope.row.MinAbsPartialCharge }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="FpDensityMorgan1" prop="FpDensityMorgan1" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.FpDensityMorgan1" placement="top-start">
                <div slot="content">{{ scope.row.FpDensityMorgan1 }}</div>
                <div class="btn">{{ scope.row.FpDensityMorgan1 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="FpDensityMorgan2" prop="FpDensityMorgan2" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.FpDensityMorgan2" placement="top-start">
                <div slot="content">{{ scope.row.FpDensityMorgan2 }}</div>
                <div class="btn">{{ scope.row.FpDensityMorgan2 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="FpDensityMorgan3" prop="FpDensityMorgan3" min-width="170">
            <template  slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.FpDensityMorgan3" placement="top-start">
                <div slot="content">{{ scope.row.FpDensityMorgan3 }}</div>
                <div class="btn">{{ scope.row.FpDensityMorgan3 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="BCUT2D_MWHI" prop="BCUT2D_MWHI" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.BCUT2D_MWHI" placement="top-start">
                <div slot="content">{{ scope.row.BCUT2D_MWHI }}</div>
                <div class="btn">{{ scope.row.BCUT2D_MWHI }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="BCUT2D_MWLOW" prop="BCUT2D_MWLOW" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.BCUT2D_MWLOW" placement="top-start">
                <div slot="content">{{ scope.row.BCUT2D_MWLOW }}</div>
                <div class="btn">{{ scope.row.BCUT2D_MWLOW }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="BCUT2D_CHGHI" prop="BCUT2D_CHGHI" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.BCUT2D_CHGHI" placement="top-start">
                <div slot="content">{{ scope.row.BCUT2D_CHGHI }}</div>
                <div class="btn">{{ scope.row.BCUT2D_CHGHI }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="BCUT2D_CHGLO" prop="BCUT2D_CHGLO" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.BCUT2D_CHGLO" placement="top-start">
                <div slot="content">{{ scope.row.BCUT2D_CHGLO }}</div>
                <div class="btn">{{ scope.row.BCUT2D_CHGLO }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="BCUT2D_LOGPHI" prop="BCUT2D_LOGPHI" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.BCUT2D_LOGPHI" placement="top-start">
                <div slot="content">{{ scope.row.BCUT2D_LOGPHI }}</div>
                <div class="btn">{{ scope.row.BCUT2D_LOGPHI }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="BCUT2D_LOGPLOW" prop="BCUT2D_LOGPLOW" min-width="190">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.BCUT2D_LOGPLOW" placement="top-start">
                <div slot="content">{{ scope.row.BCUT2D_LOGPLOW }}</div>
                <div class="btn">{{ scope.row.BCUT2D_LOGPLOW }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="BCUT2D_MRHI" prop="BCUT2D_MRHI" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.BCUT2D_MRHI" placement="top-start">
                <div slot="content">{{ scope.row.BCUT2D_MRHI }}</div>
                <div class="btn">{{ scope.row.BCUT2D_MRHI }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="BCUT2D_MRLOW" prop="BCUT2D_MRLOW" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.BCUT2D_MRLOW" placement="top-start">
                <div slot="content">{{ scope.row.BCUT2D_MRLOW }}</div>
                <div class="btn">{{ scope.row.BCUT2D_MRLOW }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="AvgIpc" prop="AvgIpc" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.AvgIpc" placement="top-start">
                <div slot="content">{{ scope.row.AvgIpc }}</div>
                <div class="btn">{{ scope.row.AvgIpc }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="BalabanJ" prop="BalabanJ" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.BalabanJ" placement="top-start">
                <div slot="content">{{ scope.row.BalabanJ }}</div>
                <div class="btn">{{ scope.row.BalabanJ }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="BertzCT" prop="BertzCT" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.BertzCT" placement="top-start">
                <div slot="content">{{ scope.row.BertzCT }}</div>
                <div class="btn">{{ scope.row.BertzCT }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Chi0" prop="Chi0" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Chi0" placement="top-start">
                <div slot="content">{{ scope.row.Chi0 }}</div>
                <div class="btn">{{ scope.row.Chi0 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Chi0n" prop="Chi0n" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Chi0n" placement="top-start">
                <div slot="content">{{ scope.row.Chi0n }}</div>
                <div class="btn">{{ scope.row.Chi0n }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Chi0v" prop="Chi0v" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Chi0v" placement="top-start">
                <div slot="content">{{ scope.row.Chi0v }}</div>
                <div class="btn">{{ scope.row.Chi0v }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Chi1" prop="Chi1" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Chi1" placement="top-start">
                <div slot="content">{{ scope.row.Chi1 }}</div>
                <div class="btn">{{ scope.row.Chi1 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Chi1n" prop="Chi1n" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Chi1n" placement="top-start">
                <div slot="content">{{ scope.row.Chi1n }}</div>
                <div class="btn">{{ scope.row.Chi1n }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Chi1v" prop="Chi1v" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Chi1v" placement="top-start">
                <div slot="content">{{ scope.row.Chi1v }}</div>
                <div class="btn">{{ scope.row.Chi1v }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Chi2n" prop="Chi2n" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Chi2n" placement="top-start">
                <div slot="content">{{ scope.row.Chi2n }}</div>
                <div class="btn">{{ scope.row.Chi2n }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Chi2v" prop="Chi2v" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Chi2v" placement="top-start">
                <div slot="content">{{ scope.row.Chi2v }}</div>
                <div class="btn">{{ scope.row.Chi2v }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Chi3n" prop="Chi3n" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Chi3n" placement="top-start">
                <div slot="content">{{ scope.row.Chi3n }}</div>
                <div class="btn">{{ scope.row.Chi3n }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Chi3v" prop="Chi3v" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Chi3v" placement="top-start">
                <div slot="content">{{ scope.row.Chi3v }}</div>
                <div class="btn">{{ scope.row.Chi3v }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Chi4n" prop="Chi4n" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Chi4n" placement="top-start">
                <div slot="content">{{ scope.row.Chi4n }}</div>
                <div class="btn">{{ scope.row.Chi4n }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Chi4v" prop="Chi4v" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Chi4v" placement="top-start">
                <div slot="content">{{ scope.row.Chi4v }}</div>
                <div class="btn">{{ scope.row.Chi4v }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="HallKierAlpha" prop="HallKierAlpha" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.HallKierAlpha" placement="top-start">
                <div slot="content">{{ scope.row.HallKierAlpha }}</div>
                <div class="btn">{{ scope.row.HallKierAlpha }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Ipc" prop="Ipc" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Ipc" placement="top-start">
                <div slot="content">{{ scope.row.Ipc }}</div>
                <div class="btn">{{ scope.row.Ipc }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Kappa1" prop="Kappa1" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Kappa1" placement="top-start">
                <div slot="content">{{ scope.row.Kappa1 }}</div>
                <div class="btn">{{ scope.row.Kappa1 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Kappa2" prop="Kappa2" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Kappa2" placement="top-start">
                <div slot="content">{{ scope.row.Kappa2 }}</div>
                <div class="btn">{{ scope.row.Kappa2 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="Kappa3" prop="Kappa3" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Kappa3" placement="top-start">
                <div slot="content">{{ scope.row.Kappa3 }}</div>
                <div class="btn">{{ scope.row.Kappa3 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="LabuteASA" prop="LabuteASA" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.LabuteASA" placement="top-start">
                <div slot="content">{{ scope.row.LabuteASA }}</div>
                <div class="btn">{{ scope.row.LabuteASA }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="PEOE_VSA1" prop="PEOE_VSA1" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.PEOE_VSA1" placement="top-start">
                <div slot="content">{{ scope.row.PEOE_VSA1 }}</div>
                <div class="btn">{{ scope.row.PEOE_VSA1 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="PEOE_VSA2" prop="PEOE_VSA2" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.PEOE_VSA2" placement="top-start">
                <div slot="content">{{ scope.row.PEOE_VSA2}}</div>
                <div class="btn">{{ scope.row.PEOE_VSA2 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="PEOE_VSA3" prop="PEOE_VSA3" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.PEOE_VSA3" placement="top-start">
                <div slot="content">{{ scope.row.PEOE_VSA3 }}</div>
                <div class="btn">{{ scope.row.PEOE_VSA3 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="PEOE_VSA4" prop="PEOE_VSA4" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.PEOE_VSA4" placement="top-start">
                <div slot="content">{{ scope.row.PEOE_VSA4 }}</div>
                <div class="btn">{{ scope.row.PEOE_VSA4 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="PEOE_VSA5" prop="PEOE_VSA5" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.PEOE_VSA5" placement="top-start">
                <div slot="content">{{ scope.row.PEOE_VSA5 }}</div>
                <div class="btn">{{ scope.row.PEOE_VSA5 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="PEOE_VSA6" prop="PEOE_VSA6" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.PEOE_VSA6" placement="top-start">
                <div slot="content">{{ scope.row.PEOE_VSA6 }}</div>
                <div class="btn">{{ scope.row.PEOE_VSA6 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="PEOE_VSA7" prop="PEOE_VSA7" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.PEOE_VSA7" placement="top-start">
                <div slot="content">{{ scope.row.PEOE_VSA7 }}</div>
                <div class="btn">{{ scope.row.PEOE_VSA7 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="PEOE_VSA8" prop="PEOE_VSA8" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.PEOE_VSA8" placement="top-start">
                <div slot="content">{{ scope.row.PEOE_VSA8 }}</div>
                <div class="btn">{{ scope.row.PEOE_VSA8 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="PEOE_VSA9" prop="PEOE_VSA9" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.PEOE_VSA9" placement="top-start">
                <div slot="content">{{ scope.row.PEOE_VSA9 }}</div>
                <div class="btn">{{ scope.row.PEOE_VSA9 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="PEOE_VSA10" prop="PEOE_VSA10" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.PEOE_VSA10" placement="top-start">
                <div slot="content">{{ scope.row.PEOE_VSA10 }}</div>
                <div class="btn">{{ scope.row.PEOE_VSA10 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="PEOE_VSA11" prop="PEOE_VSA11" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.PEOE_VSA11" placement="top-start">
                <div slot="content">{{ scope.row.PEOE_VSA11 }}</div>
                <div class="btn">{{ scope.row.PEOE_VSA11 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="PEOE_VSA12" prop="PEOE_VSA12" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.PEOE_VSA12" placement="top-start">
                <div slot="content">{{ scope.row.PEOE_VSA12}}</div>
                <div class="btn">{{ scope.row.PEOE_VSA12 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="PEOE_VSA13" prop="PEOE_VSA13" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.PEOE_VSA13" placement="top-start">
                <div slot="content">{{ scope.row.PEOE_VSA13 }}</div>
                <div class="btn">{{ scope.row.PEOE_VSA13 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="PEOE_VSA14" prop="PEOE_VSA14" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.PEOE_VSA14" placement="top-start">
                <div slot="content">{{ scope.row.PEOE_VSA14 }}</div>
                <div class="btn">{{ scope.row.PEOE_VSA14 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SMR_VSA1" prop="SMR_VSA1" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SMR_VSA1" placement="top-start">
                <div slot="content">{{ scope.row.SMR_VSA1 }}</div>
                <div class="btn">{{ scope.row.SMR_VSA1 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SMR_VSA2" prop="SMR_VSA2" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SMR_VSA2" placement="top-start">
                <div slot="content">{{ scope.row.SMR_VSA2 }}</div>
                <div class="btn">{{ scope.row.SMR_VSA2 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SMR_VSA3" prop="SMR_VSA3" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SMR_VSA3" placement="top-start">
                <div slot="content">{{ scope.row.SMR_VSA3 }}</div>
                <div class="btn">{{ scope.row.SMR_VSA3 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SMR_VSA4" prop="SMR_VSA4" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SMR_VSA4" placement="top-start">
                <div slot="content">{{ scope.row.SMR_VSA4 }}</div>
                <div class="btn">{{ scope.row.SMR_VSA4 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SMR_VSA5" prop="SMR_VSA5" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SMR_VSA5" placement="top-start">
                <div slot="content">{{ scope.row.SMR_VSA5 }}</div>
                <div class="btn">{{ scope.row.SMR_VSA5 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SMR_VSA6" prop="SMR_VSA6" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SMR_VSA6" placement="top-start">
                <div slot="content">{{ scope.row.SMR_VSA6 }}</div>
                <div class="btn">{{ scope.row.SMR_VSA6 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SMR_VSA7" prop="SMR_VSA7" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SMR_VSA7" placement="top-start">
                <div slot="content">{{ scope.row.SMR_VSA7 }}</div>
                <div class="btn">{{ scope.row.SMR_VSA7 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SMR_VSA8" prop="SMR_VSA8" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SMR_VSA8" placement="top-start">
                <div slot="content">{{ scope.row.SMR_VSA8 }}</div>
                <div class="btn">{{ scope.row.SMR_VSA8 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SMR_VSA9" prop="SMR_VSA9" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SMR_VSA9" placement="top-start">
                <div slot="content">{{ scope.row.SMR_VSA9 }}</div>
                <div class="btn">{{ scope.row.SMR_VSA9 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SMR_VSA10" prop="SMR_VSA10" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SMR_VSA10" placement="top-start">
                <div slot="content">{{ scope.row.SMR_VSA10 }}</div>
                <div class="btn">{{ scope.row.SMR_VSA10 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SlogP_VSA1" prop="SlogP_VSA1" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SlogP_VSA1" placement="top-start">
                <div slot="content">{{ scope.row.SlogP_VSA1 }}</div>
                <div class="btn">{{ scope.row.SlogP_VSA1 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SlogP_VSA2" prop="SlogP_VSA2" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SlogP_VSA2" placement="top-start">
                <div slot="content">{{ scope.row.SlogP_VSA2 }}</div>
                <div class="btn">{{ scope.row.SlogP_VSA2 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SlogP_VSA3" prop="SlogP_VSA3" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SlogP_VSA3" placement="top-start">
                <div slot="content">{{ scope.row.SlogP_VSA3 }}</div>
                <div class="btn">{{ scope.row.SlogP_VSA3 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SlogP_VSA4" prop="SlogP_VSA4" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SlogP_VSA4" placement="top-start">
                <div slot="content">{{ scope.row.SlogP_VSA4 }}</div>
                <div class="btn">{{ scope.row.SlogP_VSA4 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SlogP_VSA5" prop="SlogP_VSA5" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SlogP_VSA5" placement="top-start">
                <div slot="content">{{ scope.row.SlogP_VSA5 }}</div>
                <div class="btn">{{ scope.row.SlogP_VSA5 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SlogP_VSA6" prop="SlogP_VSA6" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SlogP_VSA6" placement="top-start">
                <div slot="content">{{ scope.row.SlogP_VSA6 }}</div>
                <div class="btn">{{ scope.row.SlogP_VSA6 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SlogP_VSA7" prop="SlogP_VSA7" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SlogP_VSA7" placement="top-start">
                <div slot="content">{{ scope.row.SlogP_VSA7 }}</div>
                <div class="btn">{{ scope.row.SlogP_VSA7 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SlogP_VSA8" prop="SlogP_VSA8" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SlogP_VSA8" placement="top-start">
                <div slot="content">{{ scope.row.SlogP_VSA8 }}</div>
                <div class="btn">{{ scope.row.SlogP_VSA8 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SlogP_VSA9" prop="SlogP_VSA9" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SlogP_VSA9" placement="top-start">
                <div slot="content">{{ scope.row.SlogP_VSA9 }}</div>
                <div class="btn">{{ scope.row.SlogP_VSA9 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SlogP_VSA10" prop="SlogP_VSA10" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SlogP_VSA10" placement="top-start">
                <div slot="content">{{ scope.row.SlogP_VSA10 }}</div>
                <div class="btn">{{ scope.row.SlogP_VSA10 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SlogP_VSA11" prop="SlogP_VSA11" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SlogP_VSA11" placement="top-start">
                <div slot="content">{{ scope.row.SlogP_VSA11 }}</div>
                <div class="btn">{{ scope.row.SlogP_VSA11 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="SlogP_VSA12" prop="SlogP_VSA12" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SlogP_VSA12" placement="top-start">
                <div slot="content">{{ scope.row.SlogP_VSA12 }}</div>
                <div class="btn">{{ scope.row.SlogP_VSA12}}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="TPSA" prop="TPSA" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.TPSA" placement="top-start">
                <div slot="content">{{ scope.row.TPSA }}</div>
                <div class="btn">{{ scope.row.TPSA }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="EState_VSA1" prop="EState_VSA1" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.EState_VSA1" placement="top-start">
                <div slot="content">{{ scope.row.EState_VSA1 }}</div>
                <div class="btn">{{ scope.row.EState_VSA1 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="EState_VSA2" prop="EState_VSA2" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.EState_VSA2" placement="top-start">
                <div slot="content">{{ scope.row.EState_VSA2 }}</div>
                <div class="btn">{{ scope.row.EState_VSA2 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="EState_VSA3" prop="EState_VSA3" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.EState_VSA3" placement="top-start">
                <div slot="content">{{ scope.row.EState_VSA3 }}</div>
                <div class="btn">{{ scope.row.EState_VSA3 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="EState_VSA4" prop="EState_VSA4" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.EState_VSA4" placement="top-start">
                <div slot="content">{{ scope.row.EState_VSA4 }}</div>
                <div class="btn">{{ scope.row.EState_VSA4 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="EState_VSA5" prop="EState_VSA5" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.EState_VSA5" placement="top-start">
                <div slot="content">{{ scope.row.EState_VSA5 }}</div>
                <div class="btn">{{ scope.row.EState_VSA5 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="EState_VSA6" prop="EState_VSA6" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.EState_VSA6" placement="top-start">
                <div slot="content">{{ scope.row.EState_VSA6 }}</div>
                <div class="btn">{{ scope.row.EState_VSA6 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="EState_VSA7" prop="EState_VSA7" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.EState_VSA7" placement="top-start">
                <div slot="content">{{ scope.row.EState_VSA7 }}</div>
                <div class="btn">{{ scope.row.EState_VSA7 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="EState_VSA8" prop="EState_VSA8" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.EState_VSA8" placement="top-start">
                <div slot="content">{{ scope.row.EState_VSA8 }}</div>
                <div class="btn">{{ scope.row.EState_VSA8 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="EState_VSA9" prop="EState_VSA9" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.EState_VSA9" placement="top-start">
                <div slot="content">{{ scope.row.EState_VSA9 }}</div>
                <div class="btn">{{ scope.row.EState_VSA9}}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="EState_VSA10" prop="EState_VSA10" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.EState_VSA10" placement="top-start">
                <div slot="content">{{ scope.row.EState_VSA10 }}</div>
                <div class="btn">{{ scope.row.EState_VSA10 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="VSA_EState1" prop="VSA_EState1" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.VSA_EState1" placement="top-start">
                <div slot="content">{{ scope.row.VSA_EState1 }}</div>
                <div class="btn">{{ scope.row.VSA_EState1 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="VSA_EState2" prop="VSA_EState2" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.VSA_EState2" placement="top-start">
                <div slot="content">{{ scope.row.VSA_EState2 }}</div>
                <div class="btn">{{ scope.row.VSA_EState2 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="VSA_EState3" prop="VSA_EState3" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.VSA_EState3" placement="top-start">
                <div slot="content">{{ scope.row.VSA_EState3 }}</div>
                <div class="btn">{{ scope.row.VSA_EState3}}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="VSA_EState4" prop="VSA_EState4" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.VSA_EState4" placement="top-start">
                <div slot="content">{{ scope.row.VSA_EState4 }}</div>
                <div class="btn">{{ scope.row.VSA_EState4 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="VSA_EState5" prop="VSA_EState5" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.VSA_EState5" placement="top-start">
                <div slot="content">{{ scope.row.VSA_EState5 }}</div>
                <div class="btn">{{ scope.row.VSA_EState5 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="VSA_EState6" prop="VSA_EState6" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.VSA_EState6" placement="top-start">
                <div slot="content">{{ scope.row.VSA_EState6 }}</div>
                <div class="btn">{{ scope.row.VSA_EState6 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="VSA_EState7" prop="VSA_EState7" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.VSA_EState7" placement="top-start">
                <div slot="content">{{ scope.row.VSA_EState7 }}</div>
                <div class="btn">{{ scope.row.VSA_EState7 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="VSA_EState8" prop="VSA_EState8" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.VSA_EState8" placement="top-start">
                <div slot="content">{{ scope.row.VSA_EState8 }}</div>
                <div class="btn">{{ scope.row.VSA_EState8 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="VSA_EState9" prop="VSA_EState9" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.VSA_EState9" placement="top-start">
                <div slot="content">{{ scope.row.VSA_EState9 }}</div>
                <div class="btn">{{ scope.row.VSA_EState9 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="VSA_EState10" prop="VSA_EState10" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.VSA_EState10" placement="top-start">
                <div slot="content">{{ scope.row.VSA_EState10 }}</div>
                <div class="btn">{{ scope.row.VSA_EState10 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="FractionCSP3" prop="FractionCSP3" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.FractionCSP3" placement="top-start">
                <div slot="content">{{ scope.row.FractionCSP3 }}</div>
                <div class="btn">{{ scope.row.FractionCSP3 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="HeavyAtomCount" prop="HeavyAtomCount" min-width="190">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.HeavyAtomCount" placement="top-start">
                <div slot="content">{{ scope.row.HeavyAtomCount }}</div>
                <div class="btn">{{ scope.row.HeavyAtomCount }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NHOHCount" prop="NHOHCount" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NHOHCount" placement="top-start">
                <div slot="content">{{ scope.row.NHOHCount }}</div>
                <div class="btn">{{ scope.row.NHOHCount }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NOCount" prop="NOCount" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NOCount" placement="top-start">
                <div slot="content">{{ scope.row.NOCount }}</div>
                <div class="btn">{{ scope.row.NOCount }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumAliphaticCarbocycles" prop="NumAliphaticCarbocycles" min-width="240">
            <template  slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumAliphaticCarbocycles" placement="top-start">
                <div slot="content">{{ scope.row.NumAliphaticCarbocycles }}</div>
                <div class="btn">{{ scope.row.NumAliphaticCarbocycles }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumAliphaticHeterocycles" prop="NumAliphaticHeterocycles" min-width="240">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumAliphaticHeterocycles" placement="top-start">
                <div slot="content">{{ scope.row.NumAliphaticHeterocycles }}</div>
                <div class="btn">{{ scope.row.NumAliphaticHeterocycles }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumAliphaticRings" prop="NumAliphaticRings" min-width="170">
            <template  slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumAliphaticRings" placement="top-start">
                <div slot="content">{{ scope.row.NumAliphaticRings }}</div>
                <div class="btn">{{ scope.row.NumAliphaticRings }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumAromaticCarbocycles" prop="NumAromaticCarbocycles" min-width="240"><template
              slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumAromaticCarbocycles" placement="top-start">
                <div slot="content">{{ scope.row.NumAromaticCarbocycles }}</div>
                <div class="btn">{{ scope.row.NumAromaticCarbocycles }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumAromaticHeterocycles" prop="NumAromaticHeterocycles" min-width="240"><template
              slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumAromaticHeterocycles" placement="top-start">
                <div slot="content">{{ scope.row.NumAromaticHeterocycles }}</div>
                <div class="btn">{{ scope.row.NumAromaticHeterocycles }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumAromaticRings" prop="NumAromaticRings" min-width="170"><template
              slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumAromaticRings" placement="top-start">
                <div slot="content">{{ scope.row.NumAromaticRings }}</div>
                <div class="btn">{{ scope.row.NumAromaticRings }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumHAcceptors" prop="NumHAcceptors" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumHAcceptors" placement="top-start">
                <div slot="content">{{ scope.row.NumHAcceptors }}</div>
                <div class="btn">{{ scope.row.NumHAcceptors }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumHDonors" prop="NumHDonors" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumHDonors" placement="top-start">
                <div slot="content">{{ scope.row.NumHDonors }}</div>
                <div class="btn">{{ scope.row.NumHDonors }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumHeteroatoms" prop="NumHeteroatoms" min-width="170"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumHeteroatoms" placement="top-start">
                <div slot="content">{{ scope.row.NumHeteroatoms }}</div>
                <div class="btn">{{ scope.row.NumHeteroatoms }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumRotatableBonds" prop="NumRotatableBonds" min-width="210"><template
              slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumRotatableBonds" placement="top-start">
                <div slot="content">{{ scope.row.NumRotatableBonds }}</div>
                <div class="btn">{{ scope.row.NumRotatableBonds }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumSaturatedCarbocycles" prop="NumSaturatedCarbocycles" min-width="240"><template
              slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumSaturatedCarbocycles" placement="top-start">
                <div slot="content">{{ scope.row.NumSaturatedCarbocycles }}</div>
                <div class="btn">{{ scope.row.NumSaturatedCarbocycles }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumSaturatedHeterocycles" prop="NumSaturatedHeterocycles" min-width="240"><template
              slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumSaturatedHeterocycles" placement="top-start">
                <div slot="content">{{ scope.row.NumSaturatedHeterocycles }}</div>
                <div class="btn">{{ scope.row.NumSaturatedHeterocycles }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="NumSaturatedRings" prop="NumSaturatedRings" min-width="210"><template
              slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.NumSaturatedRings" placement="top-start">
                <div slot="content">{{ scope.row.NumSaturatedRings }}</div>
                <div class="btn">{{ scope.row.NumSaturatedRings }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="RingCount" prop="RingCount" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.RingCount" placement="top-start">
                <div slot="content">{{ scope.row.RingCount }}</div>
                <div class="btn">{{ scope.row.RingCount }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="MolLogP" prop="MolLogP" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.MolLogP" placement="top-start">
                <div slot="content">{{ scope.row.MolLogP }}</div>
                <div class="btn">{{ scope.row.MolLogP }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="MolMR" prop="MolMR" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.MolMR" placement="top-start">
                <div slot="content">{{ scope.row.MolMR }}</div>
                <div class="btn">{{ scope.row.MolMR }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_Al_COO" prop="fr_Al_COO" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_Al_COO" placement="top-start">
                <div slot="content">{{ scope.row.fr_Al_COO }}</div>
                <div class="btn">{{ scope.row.fr_Al_COO }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_Al_OH" prop="fr_Al_OH" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_Al_OH" placement="top-start">
                <div slot="content">{{ scope.row.fr_Al_OH }}</div>
                <div class="btn">{{ scope.row.fr_Al_OH }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_Al_OH_noTert" prop="fr_Al_OH_noTert" min-width="170"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_Al_OH_noTert" placement="top-start">
                <div slot="content">{{ scope.row.fr_Al_OH_noTert }}</div>
                <div class="btn">{{ scope.row.fr_Al_OH_noTert }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_ArN" prop="fr_ArN" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_ArN" placement="top-start">
                <div slot="content">{{ scope.row.fr_ArN }}</div>
                <div class="btn">{{ scope.row.fr_ArN }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_Ar_COO" prop="fr_Ar_COO" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_Ar_COO" placement="top-start">
                <div slot="content">{{ scope.row.fr_Ar_COO }}</div>
                <div class="btn">{{ scope.row.fr_Ar_COO }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_Ar_N" prop="fr_Ar_N" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_Ar_N" placement="top-start">
                <div slot="content">{{ scope.row.fr_Ar_N }}</div>
                <div class="btn">{{ scope.row.fr_Ar_N }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_Ar_NH" prop="fr_Ar_NH" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_Ar_NH" placement="top-start">
                <div slot="content">{{ scope.row.fr_Ar_NH }}</div>
                <div class="btn">{{ scope.row.fr_Ar_NH }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_Ar_OH" prop="fr_Ar_OH" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_Ar_OH" placement="top-start">
                <div slot="content">{{ scope.row.fr_Ar_OH }}</div>
                <div class="btn">{{ scope.row.fr_Ar_OH }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_COO" prop="fr_COO" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_COO" placement="top-start">
                <div slot="content">{{ scope.row.fr_COO }}</div>
                <div class="btn">{{ scope.row.fr_COO }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_COO2" prop="fr_COO2" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_COO2" placement="top-start">
                <div slot="content">{{ scope.row.fr_COO2 }}</div>
                <div class="btn">{{ scope.row.fr_COO2 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_C_O" prop="fr_C_O" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_C_O" placement="top-start">
                <div slot="content">{{ scope.row.fr_C_O }}</div>
                <div class="btn">{{ scope.row.fr_C_O }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_C_O_noCOO" prop="fr_C_O_noCOO" min-width="170"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_C_O_noCOO" placement="top-start">
                <div slot="content">{{ scope.row.fr_C_O_noCOO }}</div>
                <div class="btn">{{ scope.row.fr_C_O_noCOO }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_C_S" prop="fr_C_S" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_C_S" placement="top-start">
                <div slot="content">{{ scope.row.fr_C_S }}</div>
                <div class="btn">{{ scope.row.fr_C_S }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_HOCCN" prop="fr_HOCCN" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_HOCCN" placement="top-start">
                <div slot="content">{{ scope.row.fr_HOCCN }}</div>
                <div class="btn">{{ scope.row.fr_HOCCN }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_Imine" prop="fr_Imine" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_Imine" placement="top-start">
                <div slot="content">{{ scope.row.fr_Imine }}</div>
                <div class="btn">{{ scope.row.fr_Imine }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_NH0" prop="fr_NH0" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_NH0" placement="top-start">
                <div slot="content">{{ scope.row.fr_NH0 }}</div>
                <div class="btn">{{ scope.row.fr_NH0 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_NH1" prop="fr_NH1" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_NH1" placement="top-start">
                <div slot="content">{{ scope.row.fr_NH1 }}</div>
                <div class="btn">{{ scope.row.fr_NH1 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_NH2" prop="fr_NH2" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_NH2" placement="top-start">
                <div slot="content">{{ scope.row.fr_NH2 }}</div>
                <div class="btn">{{ scope.row.fr_NH2 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_N_O" prop="fr_N_O" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_N_O" placement="top-start">
                <div slot="content">{{ scope.row.fr_N_O }}</div>
                <div class="btn">{{ scope.row.fr_N_O }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_Ndealkylation1" prop="fr_Ndealkylation1" min-width="170"><template
              slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_Ndealkylation1" placement="top-start">
                <div slot="content">{{ scope.row.fr_Ndealkylation1 }}</div>
                <div class="btn">{{ scope.row.fr_Ndealkylation1 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_Ndealkylation2" prop="fr_Ndealkylation2" min-width="170"><template
              slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_Ndealkylation2" placement="top-start">
                <div slot="content">{{ scope.row.fr_Ndealkylation2 }}</div>
                <div class="btn">{{ scope.row.fr_Ndealkylation2 }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_Nhpyrrole" prop="fr_Nhpyrrole" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_Nhpyrrole" placement="top-start">
                <div slot="content">{{ scope.row.fr_Nhpyrrole }}</div>
                <div class="btn">{{ scope.row.fr_Nhpyrrole }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_SH" prop="fr_SH" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_SH" placement="top-start">
                <div slot="content">{{ scope.row.fr_SH }}</div>
                <div class="btn">{{ scope.row.fr_SH }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_aldehyde" prop="fr_aldehyde" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_aldehyde" placement="top-start">
                <div slot="content">{{ scope.row.fr_aldehyde }}</div>
                <div class="btn">{{ scope.row.fr_aldehyde }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_alkyl_carbamate" prop="fr_alkyl_carbamate" min-width="170"><template
              slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_alkyl_carbamate" placement="top-start">
                <div slot="content">{{ scope.row.fr_alkyl_carbamate }}</div>
                <div class="btn">{{ scope.row.fr_alkyl_carbamate }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_alkyl_halide" prop="fr_alkyl_halide" min-width="170"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_alkyl_halide" placement="top-start">
                <div slot="content">{{ scope.row.fr_alkyl_halide }}</div>
                <div class="btn">{{ scope.row.fr_alkyl_halide }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_allylic_oxid" prop="fr_allylic_oxid" min-width="170"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_allylic_oxid" placement="top-start">
                <div slot="content">{{ scope.row.fr_allylic_oxid }}</div>
                <div class="btn">{{ scope.row.fr_allylic_oxid }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_amide" prop="fr_amide" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_amide" placement="top-start">
                <div slot="content">{{ scope.row.fr_amide}}</div>
                <div class="btn">{{ scope.row.fr_amide }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_amidine" prop="fr_amidine" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_amidine" placement="top-start">
                <div slot="content">{{ scope.row.fr_amidine }}</div>
                <div class="btn">{{ scope.row.fr_amidine }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_aniline" prop="fr_aniline" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_aniline" placement="top-start">
                <div slot="content">{{ scope.row.fr_aniline }}</div>
                <div class="btn">{{ scope.row.fr_aniline }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_aryl_methyl" prop="fr_aryl_methyl" min-width="170"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_aryl_methyl" placement="top-start">
                <div slot="content">{{ scope.row.fr_aryl_methyl }}</div>
                <div class="btn">{{ scope.row.fr_aryl_methyl }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_azide" prop="fr_azide" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_azide" placement="top-start">
                <div slot="content">{{ scope.row.fr_azide }}</div>
                <div class="btn">{{ scope.row.fr_azide }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_barbitur" prop="fr_barbitur" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_barbitur" placement="top-start">
                <div slot="content">{{ scope.row.fr_barbitur }}</div>
                <div class="btn">{{ scope.row.fr_barbitur }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_benzene" prop="fr_benzene" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_benzene" placement="top-start">
                <div slot="content">{{ scope.row.fr_benzene }}</div>
                <div class="btn">{{ scope.row.fr_benzene }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_benzodiazepine" prop="fr_benzodiazepine" min-width="170"><template
              slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_benzodiazepine" placement="top-start">
                <div slot="content">{{ scope.row.fr_benzodiazepine }}</div>
                <div class="btn">{{ scope.row.fr_benzodiazepine }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_bicyclic" prop="fr_bicyclic" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_bicyclic" placement="top-start">
                <div slot="content">{{ scope.row.fr_bicyclic }}</div>
                <div class="btn">{{ scope.row.fr_bicyclic }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_diazo" prop="fr_diazo" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_diazo" placement="top-start">
                <div slot="content">{{ scope.row.fr_diazo }}</div>
                <div class="btn">{{ scope.row.fr_diazo }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_dihydropyridine" prop="fr_dihydropyridine" min-width="170"><template
              slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_dihydropyridine" placement="top-start">
                <div slot="content">{{ scope.row.fr_dihydropyridine }}</div>
                <div class="btn">{{ scope.row.fr_dihydropyridine }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_epoxide" prop="fr_epoxide" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_epoxide" placement="top-start">
                <div slot="content">{{ scope.row.fr_epoxide }}</div>
                <div class="btn">{{ scope.row.fr_epoxide }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_ester" prop="fr_ester" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_ester" placement="top-start">
                <div slot="content">{{ scope.row.fr_ester }}</div>
                <div class="btn">{{ scope.row.fr_ester }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_ether" prop="fr_ether" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_ether" placement="top-start">
                <div slot="content">{{ scope.row.fr_ether }}</div>
                <div class="btn">{{ scope.row.fr_ether }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_furan" prop="fr_furan" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_furan" placement="top-start">
                <div slot="content">{{ scope.row.fr_furan }}</div>
                <div class="btn">{{ scope.row.fr_furan }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_guanido" prop="fr_guanido" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_guanido" placement="top-start">
                <div slot="content">{{ scope.row.fr_guanido }}</div>
                <div class="btn">{{ scope.row.fr_guanido }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_halogen" prop="fr_halogen" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_halogen" placement="top-start">
                <div slot="content">{{ scope.row.fr_halogen }}</div>
                <div class="btn">{{ scope.row.fr_halogen }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_hdrzine" prop="fr_hdrzine" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_hdrzine" placement="top-start">
                <div slot="content">{{ scope.row.fr_hdrzine }}</div>
                <div class="btn">{{ scope.row.fr_hdrzine }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_hdrzone" prop="fr_hdrzone" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_hdrzone" placement="top-start">
                <div slot="content">{{ scope.row.fr_hdrzone }}</div>
                <div class="btn">{{ scope.row.fr_hdrzone }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_imidazole" prop="fr_imidazole" min-width="170"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_imidazole" placement="top-start">
                <div slot="content">{{ scope.row.fr_imidazole }}</div>
                <div class="btn">{{ scope.row.fr_imidazole }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_imide" prop="fr_imide" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_imide" placement="top-start">
                <div slot="content">{{ scope.row.fr_imide }}</div>
                <div class="btn">{{ scope.row.fr_imide}}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_isocyan" prop="fr_isocyan" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_isocyan" placement="top-start">
                <div slot="content">{{ scope.row.fr_isocyan }}</div>
                <div class="btn">{{ scope.row.fr_isocyan }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_isothiocyan" prop="fr_isothiocyan" min-width="170"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_isothiocyan" placement="top-start">
                <div slot="content">{{ scope.row.fr_isothiocyan }}</div>
                <div class="btn">{{ scope.row.fr_isothiocyan }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_ketone" prop="fr_ketone" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_ketone" placement="top-start">
                <div slot="content">{{ scope.row.fr_ketone }}</div>
                <div class="btn">{{ scope.row.fr_ketone }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_ketone_Topliss" prop="fr_ketone_Topliss" min-width="170"><template
              slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_ketone_Topliss" placement="top-start">
                <div slot="content">{{ scope.row.fr_ketone_Topliss }}</div>
                <div class="btn">{{ scope.row.fr_ketone_Topliss }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_lactam" prop="fr_lactam" min-width="150"><template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_lactam" placement="top-start">
                <div slot="content">{{ scope.row.fr_lactam }}</div>
                <div class="btn">{{ scope.row.fr_lactam }}</div>
              </el-tooltip>
            </template></el-table-column>
          <el-table-column label="fr_lactone" prop="fr_lactone" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.fr_lactone" placement="top-start">
                <div slot="content">{{ scope.row.fr_lactone }}</div>
                <div class="btn">{{ scope.row.fr_lactone }}</div>
              </el-tooltip>
            </template></el-table-column>
        </el-table>
      </div>
    </div>
    <!--===分页=====-->
    <el-pagination  :current-page="pageinfo.page" :page-sizes="[10, 20, 30, 40]" :page-size="pageinfo.size"
      layout="total, sizes, prev, pager, next, jumper" :total="pageinfo.total" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" style="margin-bottom: 3px; text-align: center;"></el-pagination>
  </div>
</template>

<script>
export default {
  name: "SearchView",
  props:['inputVal','requestUrl','searchViewActive'],
  data() {
    return {
      // 页面要显示的表格数据
      tableData: [],
      // 分页信息
      pageinfo: {
          page: 1,
          size: 10,
          total: 0,
        },
    };
  },
  created() {
  },
  methods: {
    senddata(){
      console.log(this.requestUrl)
      console.log(this.inputVal)
      console.log(this.pageinfo.size)
      console.log(this.pageinfo.page)
      this.$axios.get(this.requestUrl,
        {
          params: {
            search: this.inputVal,
            page: this.pageinfo.page,
            page_size: this.pageinfo.size,
          }
        }
      ).then((response) => {
          console.log(response)
          this.tableData = response.data.data;
          this.$emit('update:tableData', this.tableData);
          console.log(this.tableData)
          this.pageinfo.total = response.data.count; // 总记录数
          this.pageinfo.size = parseInt(response.data.size);
          this.pageinfo.page = response.data.page;
        })
        .catch(resp => {
          console.log("请求失败：" + resp.status + "," + resp.statusText);
        });
    },
    /** 切换每页显示条数 */
    handleSizeChange(val) {
      this.pageinfo.size = val;
      this.senddata();
    },
    /** 切换分页 */
    handleCurrentChange(val) {
      this.pageinfo.page = val;
      this.senddata();
    },
  }
};
</script>

<style>
/* 基本表格样式 */
/* 滚动条的容器 */
.table-box::-webkit-scrollbar {
  height: 13px;
  /* 滚动条的高度 */
}

/* 滚动条的滑块 */
.table-box::-webkit-scrollbar-thumb {
  background-color: #4091ff;
  /* 滚动条滑块的颜色 */
  border-radius: 4px;
  /* 滑块的圆角 */
}


.el-tooltip__popper.is-dark {
  display: block;
}

.el-table__header {
  table-layout: fixed;
}

.el-table {
  table-layout: fixed;
}

/* 表格头部样式 */
.el-table__header th {
  background: #f5f7fa;
  /* 表格头部背景色 */
  color: #409eff;
  /* 表格头部字体颜色 */
  text-align: center;
  /* 表格头部内容左对齐 */
  padding: 8px;
  /* 表格头部单元格内边距 */
  border: 1px solid #ddd;
  /* 表格头部边框 */
  border-bottom: 2px solid #ddd;
  /* 加粗底部边框 */
  white-space: nowrap;
  /* 文本不换行 */
}


/* 表格单元格样式 */
.el-table__row td {
  border-right: 1px solid #ddd;
  /* 单元格右侧边框 */
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  /* 单元格底部边框 */
  text-align: center;
  /* 单元格内容居中对齐 */
  padding: 8px 10px;
  /* 单元格内边距 */
  max-height: 50px;
  /* 设置最大高度 */
  overflow: hidden;
  /* 超出部分隐藏 */
  word-break: break-word;
  /* 允许长单词在单元格内换行 */
  overflow-wrap: break-word;
  /* 允许内容在单元格内换行 */
  line-height: 1.2;
  /* 根据需要调整行高 */
  text-overflow: ellipsis
}

/* 鼠标悬停时的行样式 */
.el-table__row:hover {
  background-color: #f5f7fa;
  /* 鼠标悬停时行的背景色 */
}

.el-table__row {
  height: 50px;
}

/* 分页组件的样式 */
.el-pagination {
  margin-top: 10px;
  /* 分页组件与表格的间距 */
  margin-left: 150px;
}


/* 下拉列表选项悬浮状态 */
.el-select-dropdown__item:hover {
  background-color: #f5f7fa;
  /* 鼠标悬停时选项的背景色 */
}

/* 下拉列表选项被选中状态 */
.el-select-dropdown__item.selected {
  color: #4480db;
  /* 选中文字颜色 */
  font-weight: bold;
  /* 加粗选中文字 */
}

/* 确保选中的下拉选项具有蓝色背景和字体颜色 */
.el-select-dropdown__item.selected:hover {
  background-color: #e6f1fc;
  /* 选中选项悬浮时的背景色 */
}


.el-tooltip__popper.is-dark {
    background-color: #f5f7fa !important;
    padding: 8px, 10px;
    color: #080000 !important;
    border: 10px solid #f5f7fa !important;
    max-height: 1200px;
    border-radius: 4px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12) !important;
  }

.el-tooltip__popper.is-dark .popper__arrow {
  display: none;
}
  
.btn {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

.el-input--mini{
    border-radius: 20px;
    /* 添加圆角边框 */
    border: 1px solid #dcdfe6;
    /* 边框颜色 */
    box-shadow: 0px 1px 0px rgba(0., 0, 0, 0.15);
  }
  

</style>