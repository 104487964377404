import Vue from 'vue'
import VueRouter from 'vue-router'
import innerView from '../views/innerView.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/innerView',
    name: 'innerView',
    component: innerView,
  },
  {
    path:'/',
    redirect:'/innerView'
  }
]

const router = new VueRouter({
   base: process.env.BASE_URL,
   routes
});

export default router;
