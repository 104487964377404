<template>
  <div id="app">
    <innerView v-show="currentView === 'innerView'" />
  </div>
</template>

<script>
import innerView from './views/innerView.vue';
export default{
  components: {innerView},
  data() {
    return {
      currentView: 'innerView', 
    };
  },
  methods: {
   
  }

}
</script>
<style>

</style>
