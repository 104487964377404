<template>
  <div v-if="pairsearchViewActive"  class="search">
    <div class="table-box"  style="width: 1300px; overflow-x: scroll; ">
      <div class="el-table--fit" style="width: max-content;">
        <el-table :data="tableData" class="el-table__header" tooltip-effect="dark">
          <el-table-column label="ID" prop="num" min-width="100">
            <template slot-scope="scope">
              {{ ((pageinfo.page - 1) * pageinfo.size) + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column label="CID" prop="CID" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.CID" placement="top-start">
                <div slot="content">{{ scope.row.CID }}</div>
                <div class="btn">{{ scope.row.CID }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="CSLID" prop="CSLID" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.CSLID" placement="top-start">
                <div slot="content">{{ scope.row.CSLID }}</div>
                <div class="btn">{{ scope.row.CSLID }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="GeneID" prop="GeneID" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.GeneID" placement="top-start">
                <div slot="content">{{ scope.row.GeneID }}</div>
                <div class="btn">{{ scope.row.GeneID }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="UniprotID" prop="UniprotID" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.UniprotID" placement="top-start">
                <div slot="content">{{ scope.row.UniprotID }}</div>
                <div class="btn">{{ scope.row.UniprotID }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Sequence" prop="Sequence" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Sequence" placement="top-start">
                <div slot="content">{{ scope.row.Sequence }}</div>
                <div class="btn">{{ scope.row.Sequence }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Sequence_cllustalo_截短头尾" prop="Sequence_cllustalo_截短头尾" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Sequence_cllustalo_截短头尾" placement="top-start">
                <div slot="content">{{ scope.row.Sequence_cllustalo_截短头尾 }}</div>
                <div class="btn">{{ scope.row.Sequence_cllustalo_截短头尾 }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Responsive" prop="Responsive" min-width="170">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.Responsive" placement="top-start">
                <div slot="content">{{ scope.row.Responsivek}}</div>
                <div class="btn">{{ scope.row.Responsive }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="SMILE" prop="SMILE" min-width="150">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.SMILE" placement="top-start">
                <div slot="content">{{ scope.row.SMILE }}</div>
                <div class="btn">{{ scope.row.SMILE }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--===分页=====-->
    <el-pagination  :current-page="pageinfo.page" :page-sizes="[10, 20, 30, 40]" :page-size="pageinfo.size"
      layout="total, sizes, prev, pager, next, jumper" :total="pageinfo.total" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" style="margin-bottom: 3px; text-align: center;"></el-pagination>
  </div>
</template>

<script>
export default {
  name: "pairSearchView",
  props:['inputVal','requestUrl','pairsearchViewActive',],
  data() {
    return {
      // 页面要显示的表格数据
      tableData: [],
      // 分页信息
      pageinfo: {
          page: 1,
          size: 10,
          total: 0,
        },
    };
  },
  created() {
  },
  methods: {
    senddata(){
      console.log(this.requestUrl)
      console.log(this.inputVal)
      console.log(this.pageinfo.size)
      console.log(this.pageinfo.page)
      this.$axios.get(this.requestUrl,
        {
          params: {
            search: this.inputVal,
            page: this.pageinfo.page,
            page_size: this.pageinfo.size,
          }
        }
      ).then((response) => {
          console.log(response)
          this.tableData = response.data.data;
          console.log(this.tableData)
          this.pageinfo.total = response.data.count; // 总记录数
          this.pageinfo.size = parseInt(response.data.size);
          this.pageinfo.page = response.data.page;
        })
        .catch(resp => {
          console.log("请求失败：" + resp.status + "," + resp.statusText);
        });
    },
    /** 切换每页显示条数 */
    handleSizeChange(val) {
      this.pageinfo.size = val;
      this.senddata();
    },
    /** 切换分页 */
    handleCurrentChange(val) {
      this.pageinfo.page = val;
      this.senddata();
    },
  },
};
</script>

<style>
/* 基本表格样式 */
/* 滚动条的容器 */
.table-box::-webkit-scrollbar {
  height: 13px;
  /* 滚动条的高度 */
}

/* 滚动条的滑块 */
.table-box::-webkit-scrollbar-thumb {
  background-color: #4091ff;
  /* 滚动条滑块的颜色 */
  border-radius: 4px;
  /* 滑块的圆角 */
}

.el-tooltip__popper.is-dark {
  display: block;
}

.el-table__header {
  table-layout: fixed;
}

.el-table {
  table-layout: fixed;
}

/* 表格头部样式 */
.el-table__header th {
  background: #f5f7fa;
  /* 表格头部背景色 */
  color: #409eff;
  /* 表格头部字体颜色 */
  text-align: center;
  /* 表格头部内容左对齐 */
  padding: 8px;
  /* 表格头部单元格内边距 */
  border: 1px solid #ddd;
  /* 表格头部边框 */
  border-bottom: 2px solid #ddd;
  /* 加粗底部边框 */
  white-space: nowrap;
  /* 文本不换行 */
}


/* 表格单元格样式 */
.el-table__row td {
  border-right: 1px solid #ddd;
  /* 单元格右侧边框 */
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  /* 单元格底部边框 */
  text-align: center;
  /* 单元格内容居中对齐 */
  padding: 8px 10px;
  /* 单元格内边距 */
  max-height: 50px;
  /* 设置最大高度 */
  overflow: hidden;
  /* 超出部分隐藏 */
  word-break: break-word;
  /* 允许长单词在单元格内换行 */
  overflow-wrap: break-word;
  /* 允许内容在单元格内换行 */
  line-height: 1.2;
  /* 根据需要调整行高 */
  text-overflow: ellipsis
}

/* 鼠标悬停时的行样式 */
.el-table__row:hover {
  background-color: #f5f7fa;
  /* 鼠标悬停时行的背景色 */
}

.el-table__row {
  height: 50px;
}

/* 分页组件的样式 */
.el-pagination {
  margin-top: 10px;
  /* 分页组件与表格的间距 */
  margin-left: 150px;
}


/* 下拉列表选项悬浮状态 */
.el-select-dropdown__item:hover {
  background-color: #f5f7fa;
  /* 鼠标悬停时选项的背景色 */
}

/* 下拉列表选项被选中状态 */
.el-select-dropdown__item.selected {
  color: #4480db;
  /* 选中文字颜色 */
  font-weight: bold;
  /* 加粗选中文字 */
}

/* 确保选中的下拉选项具有蓝色背景和字体颜色 */
.el-select-dropdown__item.selected:hover {
  background-color: #e6f1fc;
  /* 选中选项悬浮时的背景色 */
}


.el-tooltip__popper.is-dark {
    background-color: #f5f7fa !important;
    padding: 8px, 10px;
    color: #080000 !important;
    border: 10px solid #f5f7fa !important;
    max-height: 1200px;
    border-radius: 4px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12) !important;
  }

.el-tooltip__popper.is-dark .popper__arrow {
  display: none;
}
  
.btn {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

.el-input--mini{
    border-radius: 20px;
    /* 添加圆角边框 */
    border: 1px solid #dcdfe6;
    /* 边框颜色 */
    box-shadow: 0px 1px 0px rgba(0., 0, 0, 0.15);
  }
  

</style>